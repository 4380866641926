import React from "react";
import { useEffect } from "react";

const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <h1
        style={{ fontFamily: "Josefin Sans, sans-serif" }}
        className="font30 mt-20 extraBold text-center"
      >
        No Refund Policy{" "}
      </h1>
      <br />
      Thank you for purchasing our stock market course. We strive to deliver the
      highest quality educational content and services. Please read our
      no-refund policy carefully before making a purchase.
      <br />
      <br />
      <b>1. No Refunds</b>
      <br />
      <br />
      1.1 All Sales Are Final: Due to the digital nature of our course and the
      immediate access to the content upon purchase, all sales are final. We do
      not offer refunds, exchanges, or cancellations.
      <br />
      <br />
      <b>12. Course Access</b>
      <br />
      <br />
      <b>12.1 Immediate Access:</b> Upon successful payment, you will receive
      immediate access to the full course content. As a result, we cannot offer
      refunds once access has been granted.
      <br />
      <br />
      <b>13. Exceptions</b>
      <br />
      <br />
      <b>13.1 Extenuating Circumstances:</b> While our general policy is no
      refunds, we understand that exceptional situations can arise. In cases of
      documented medical emergencies or other significant unforeseen events,
      please contact our support team at office@smartstockinvestor.in . These
      requests will be reviewed on a case-by-case basis and at our sole
      discretion.
      <br />
      <br />
      <b>14. Course Satisfaction</b>
      <br />
      <br />
      <b>14.1 Quality Assurance:</b> We are committed to providing high-quality
      educational content. If you encounter any issues or have feedback about
      the course, please reach out to our support team. We are here to assist
      and ensure you have a positive learning experience.
      <br />
      <br />
      <b>15. Contact Us</b>
      <br />
      <br />
      <b>15.1 Support:</b>If you have any questions or need assistance, please
      contact our support team at office@smartstockinvestor.in or Contact +91
      9003863517. Address: 4/447, 6th street, Aruna Nagar, K-vadamadurai,
      Thudiyalur, Coimbatore, 641017 We are here to help you. <br/><br/>By purchasing our
      stock market course, you acknowledge and agree to this no-refund policy.
      We appreciate your understanding and support.
      <br />
      <br />
    </div>
  );
};

export default Refund;
