import React from "react";
import Faq from "react-faq-component";

const data = {
  rows: [
    {
      title: "Overview of Technical Analysis",
      content:
        "👉 Definition and importance<br/>👉 Differences between technical and fundamental analysis",
    },
    {
      title: "History and Development",
      content:
        "👉 Evolution of technical analysis<br/>👉 Key figures and milestones",
    },
    {
      title: " Price Charts      ",
      content:
        "👉 Types of charts (line, bar, candlestick)<br/>👉 Time frames (intraday, daily, weekly, monthly) ",
    },
    {
      title: "Support and Resistance",
      content:
        "👉 Identifying support levels<br/> 👉 Identifying resistance levels<br/> 👉 Role in trading decisions",
    },
    {
      title: "Trend Identification",
      content: "👉Uptrend, downtrend, sideways trend",
    },
    {
      title: "Trendlines",
      content: "👉 Drawing trendlines<br/> 👉 Validating trendlines",
    },
    {
      title: "Patterns",
      content:
        " 👉 Identifying reversal patterns<br/ 👉 Triangle patterns<br/>👉 Flag patterns   ",
    },
    {
      title: "Technical Indicators (Moving Averages)",
      content:
        " 👉 Simple moving average (SMA)<br/>👉 Exponential moving average (EMA)<br/> 👉 Moving average convergence divergence (MACD) ",
    },
    {
      title: "Momentum Indicators",
      content: "👉 Relative strength index (RSI)      ",
    },
    {
      title: "Understanding market Psychology      ",
      content:
        "👉 Role of emotions in trading<br/>👉 Common psychological biases (e.g., herd mentality, overconfidence)",
    },
    {
      title: "Practical Application (case studies)      ",
      content:
        "👉 Real-world examples of technical analysis<br/>👉 Lessons from successful trades<br/>👉 Lessons from unsuccessful trades ",
    },
    {
      title: "Trading Platforms and Tools",
      content:
        "👉 Overview of popular trading platforms<br/>👉 Technical analysis software and tools",
    },
  ],
};

const styles = {
  titleTextColor: "#4d4d4d",
  rowTitleColor: "black",
  rowContentColor: "grey",
};

const config = {
  animate: true,
  arrowIcon: "⌄",
};

const TechnicalModule = () => {
  return (
    <div>
      <ul className="mt-4 text-lg">
        <h4
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="text-xl text-slate-800 text-center extraBold"
        >
          Course Module:
        </h4>
        <Faq
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          data={data}
          styles={styles}
          config={config}
        />
      </ul>
    </div>
  );
};

export default TechnicalModule;
