import React from "react";
import styled from "styled-components";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { GiStockpiles } from "react-icons/gi";

// Components
import ProjectBox from "../Elements/ProjectBox";
import BlogBox from "../Elements/BlogBox";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <HeaderInfo>
        <h1
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="text-center container w-[90%]  font30 extraBold "
        >
          Why people are successful after attending my course?🤔
        </h1>
        <p
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="text-left mb-10 mt-4 w-[85%] m-auto text-[#4d4d4d]  font16"
        >
          My students achieve remarkable success after attending my stock market
          courses because they gain a clear understanding of stock market
          basics, fundamental and technical analysis. They gain real-world
          experience through hands-on investing and swing trading strategies,
          which boosts their confidence and sharpens their trading and Investing
          skills. They become experts in investing and trading also mastering
          techniques that drive success. Additionally, the course provides
          continuous support and mentorship, ensuring that students remain
          updated with latest market trends and strategies. By gaining 100%
          practical and theoretical knowledge, they are fully ready to make
          profitable investments.
        </p>
      </HeaderInfo>

      <div className="container mb-20">
        <h3
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="text-center font20 mb-4 extraBold"
        >
          Additional Benefits👇{" "}
        </h3>
        <div className="row textCenter">
          <div className="col-xs-12 bg-aliceblue col-sm-4 col-md-4 col-lg-4">
            <BlogBox
              title="Free Personalized Guidance for Lifetime"
              text="We provide personalized feedback and guidance according to your investing goals, ensuring you make profitable investments."
              tag="company"
            />
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <BlogBox
              title="Free Lifetime Access to Updates on Telegram Community"
              text="Enrolled students will enjoy lifetime access to the stock market updates, course updates, also about market trends and new strategies."
              tag="company"
            />
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <BlogBox
              title="Free Lifetime Access to  Resources"
              text="Students gain access to Session recordings after each session, including website links, research papers, case studies, and toolkits, enhancing their learning beyond the classroom."
              tag="company"
            />
          </div>
        </div>
      </div>

      <div className="pb-20 whiteBg text-white p-10 pt-10 mb-16 bg-[#0b093b]">
        <div className="container">
          <h3
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
            className="text-center font30 mb-4 extraBold"
          >
            Join our Community⛳️{" "}
          </h3>
          <div className="row textCenter mt-">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                title="☎️"
                text="Call your mentor anytime for free support. Get help with concepts."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                title="📈"
                text="Enjoy lifetime access to expert guidance on stock market investments at no cost. "
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                title="▶️"
                text="Session notes and recordings are shared with 1 year acccess."
                action={() => alert("clicked")}
              />
            </div>
          </div>{" "}
        </div>
        <div
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="stayWidth bg-white m-auto rounded-lg mt-8"
        >
          <div>
            <h3 className="fontSize text-black font-semibold">Stay in touch</h3>
            <h3 className="text-white p-2 rounded-lg mt-1 bg-[#24A1DE]">
              <FaTelegram size={20} className="inline-block" />
              <a href="https://t.me/smartstockinvestor">
                {" "}
                <span> Join our telegram channel</span>
              </a>
            </h3>
          </div>
          <div>
            <h3 className="fontSize marginTop text-black font-semibold ">
              To know More
            </h3>
            <h3 className="text-white p-2 rounded-lg mt-1 bg-[#25d366] ">
              <span>
                <FaWhatsapp className="inline-block" size={20} />
              </span>
              <a href="https://wa.link/jfqvkq">
                {" "}
                <span> Send message here</span>
              </a>
            </h3>
          </div>
          <div>
            <h3 className="fontSize marginTop text-black font-semibold ">
              Open free Demat Account{" "}
            </h3>
            <h3 className="text-white p-2 rounded-lg mt-1 w-fit bg-[#4242c1] ">
              <GiStockpiles className="inline-block" size={20} />
              <a href="https://angel-one.onelink.me/Wjgr/77l2ce4f">
                {" "}
                <span> Open Angel One</span>
              </a>
            </h3>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
