import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    autoplay: true,
    // dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="w-[90%]  m-auto" id="coursereview">
      <h3
        style={{ fontFamily: "Josefin Sans, sans-serif" }}
        className="text-center pb-4  font30 extraBold"
      >
        Course Reviews⭐️
      </h3>
      <Slider {...settings} autoplay>
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735250/24_zpr4zf.png"
          alt="review"
        />{" "}
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735250/22_ednadt.png"
          alt="review"
        />{" "}
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735249/23_vvohfr.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735248/21_tlwtcy.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735248/20_ibmahk.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735247/19_o1eytr.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735247/9_sdfkr2.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735246/8_qw6mxt.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735252/20240228_112546_0000_n6anvx.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735245/7_k8hbjf.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735245/6_nh6xeg.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735245/4_1_jfqymq.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735244/5_wjdnll.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735243/3_1_w28d5z.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735243/1_1_mskshc.png"
          alt="review"
        />
        <img
          className="h-100 p-8"
          src="https://res.cloudinary.com/dqwt5wpun/image/upload/v1721735243/2_1_toorwp.png"
          alt="review"
        />
      </Slider>
    </div>
  );
}
const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
