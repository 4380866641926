import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url }) => {
  return (
    <>
      {/* <h4
        style={{ fontFamily: "Josefin Sans, sans-serif" }}
        className="text-center font-semibold text-xl "
      >
        Watch our previouse class demo👇
      </h4> */}

      <div className="video-player-wrapper mt-4 mb-16 flex items-start justify-center">
        <ReactPlayer
          url={url}
          className="react-player"
          playing={false}
          controls={true}
        />
      </div>
    </>
  );
};

export default VideoPlayer;
