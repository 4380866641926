import React from "react";
import TestimonialSliderVideo from "../components/Elements/TestimonialSliderVideo";
import TestimonialBox from "../components/Elements/TestimonialBox";
import ClientSlider from "../components/Elements/ClientSlider";

const CourseReview = () => {
  return (
    <React.Fragment>
      <div className="mt-28">
        <TestimonialSliderVideo />
        <div className="container">
          <ClientSlider />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseReview;
