import React from "react";
import Faq from "react-faq-component";

const data = {
  title: "FAQ (How it works)",
  rows: [
    {
      title:
        "Do I need any prior knowledge in stock market before attending this course?",
      content: `Not necessary, this course is designed for beginners in stock market.`,
    },
    {
      title: "Will I get the Course Recordings?",
      content:
        "Yes, Recordings are provided after each session and access given for 6 months.",
    },
    {
      title: "Will I get guidance even after course completion?",
      content: `Yes, free lifetime guidance is provided.`,
    },
    {
      title:
        "Will you guide me on opening a demat account and how to use the app like how to buy and sell stocks with demos?",
      content: `Yes, complete support and guidance will be given with demo.`,
    },
    {
      title:
        "Will I get daily stock market updates and important news that will keep me updated?",
      content: `Yes, In our Telegram channel we provide daily stock market updates and stock updates.`,
    },
  ],
};

const styles = {
  titleTextColor: "black",
  rowTitleColor: "#4d4d4d",
  rowContentColor: "grey",
};

const config = {
  animate: true,
  arrowIcon: "⌄",
};

export default function FaqComponent() {
  return (
    <div
      style={{ fontFamily: "Josefin Sans, sans-serif" }}
      className="w-[80%] m-auto"
    >
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
}
