import React from "react";
import Faq from "react-faq-component";

const data = {
  rows: [
    {
      title: "Overview of Fundamental Analysis",
      content: `👉 Definition and importance<br/>
      👉 Differences between fundamental and technical analysis<br/>
      👉 Qualitative analysis<br/>
      👉 Quantitative analysis
      `,
    },
    {
      title: "Macro-Economic Indicators",
      content:
        "👉 GDP<br/>👉 Inflation<br/>👉 Unemployment rates<br/>👉  Interest rates<br/>👉 Exchange rates",
    },
    {
      title: "Industry Analysis and Indicators ",
      content:
        " 👉 Industry life cycle<br/>👉 Industry performance analysis<br/>👉 Trend analysis",
    },
    {
      title: " Financial Statements Analysis",
      content:
        "👉 Balance sheet<br/>👉 Income statement<br/>👉 Cash flow statement",
    },
    {
      title: "Ratio Analysis ",
      content:
        "👉 Debt-to-equity ratio<br/> 👉 Current ratio<br/> 👉 Price-to-Earnings (P/E) ratio<br/> 👉 Return on Capital Employed (ROCE)<br/> 👉 Return on equity (ROE)<br/> ",
    },
    {
      title: "Case Studies",
      content:
        "👉 Real-world examples of fundamental analysis<br/> 👉 Lessons from successful investments<br/> 👉 Lessons from unsuccessful investments<br/> ",
    },
    {
      title: "Tools and Resources ",
      content:
        "👉 Financial databases<br/> 👉 Research reports<br/> 👉 Market commentary<br/>",
    },
  ],
};

const styles = {
  titleTextColor: "#4d4d4d",
  rowTitleColor: "black",
  rowContentColor: "grey",
};

const config = {
  animate: true,
  arrowIcon: "⌄",
};

const FundamentalModule = () => {
  return (
    <div>
      <ul className="mt-4 text-lg">
        <h4
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="text-xl text-slate-800 text-center extraBold"
        >
          Course Module:
        </h4>
        <Faq
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          data={data}
          styles={styles}
          config={config}
        />
      </ul>
    </div>
  );
};

export default FundamentalModule;
