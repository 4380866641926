import React, { useEffect } from "react";
// Sections
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import FaqComponent from "./Faq";
import WhoCanJoin from "../components/Sections/WhoCanJoin";

export default function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Services />
      <Projects />
      {/* <WhoCanJoin/> */}
      <FaqComponent/>
      {/* <Contact /> */}
      
    </>
  );
}


