import React from "react";
import Faq from "react-faq-component";

const data = {
  rows: [
    {
      title: "Introduction to the Stock Market",
      content:
        " 👉 Definition and Purpose: What is the stock market, and why does it exist?<br/> 👉 History: Brief history and evolution of stock markets.<br/> 👉 Participants: Key players in the market (investors, brokers, analysts, etc.).<br/>",
    },
    {
      title: "Understanding Stocks      ",
      content:
        " 👉 What is a Stock: Definition, and its importance<br/> 👉 Stock Features: Dividends, Stock splits, Bonus issues, Rights issue, etc.<br/>👉 Stock Categories: Blue-chip stocks, growth stocks, value stocks, etc.",
    },
    {
      title: "How the Stock Market Works",
      content:
        "👉 Stock Exchanges: About Stock exchanges (NSE, BSE.).<br/> 👉 Trading Mechanisms: How stocks are bought and sold.<br/> 👉 Order Types: Market orders, limit orders, stop loss orders, etc. ",
    },
    {
      title: "Stock Market Indices",
      content:
        "👉 Definition and Purpose: What are stock market indices, and why are they important?<br/>👉 Major Indices: Benchmark Index (Nifty, Sensex), Sectoral Index, Market Cap Index<br/>",
    },
    {
      title: " Stock Market Strategies",
      content:
        "👉 Investment Strategies: Buy and hold, growth investing, value investing.<br/> 👉 Trading Strategies: Day trading, swing trading, scalping.<br/> 👉 Diversification: Importance of a diversified portfolio.<br/> ",
    },
    {
      title: " Practical Investing with Demo ",
      content:
        "👉 Brokerage Accounts: Types of accounts, how to open an account.<br/> 👉 Using a Brokerage Platform: Placing orders, using research tools.<br/>👉 Building a Portfolio: Asset allocation, rebalancing, monitoring performance.<br/>",
    },
  ],
};

const styles = {
  titleTextColor: "#4d4d4d",
  rowTitleColor: "#403F58",
  rowContentColor: "grey",
  fontFamily: "Khula, sans-serif",
  color: "#403F58",
};

const config = {
  animate: true,
  arrowIcon: "⌄",
};

const BasicModule = () => {
  return (
    <div>
      <ul className="mt-4 text-lg">
        <h4
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="text-xl text-slate-800 text-center extraBold"
        >
          Course Module:
        </h4>
        <Faq
          // style={{ fontFamily: "Josefin Sans, sans-serif" }}
          data={data}
          styles={styles}
          config={config}
        />
      </ul>
    </div>
  );
};

export default BasicModule;
