import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./screens/Layout.jsx";
import ErrorPage from "./screens/ErrorPage.jsx";
import Landing from "./screens/Landing.jsx";
import CourseDetails from "./screens/CourseDetails.jsx";
import Success from "./screens/SuccessPage.jsx";
import StockMarketBasics from "./components/Payment/StockMarketBasics.jsx";
import Contact from "./components/Sections/Contact.jsx";
import Payment from "./components/Payment/PaymentComponent.jsx";
import FundamentalAnalysis from "./components/Payment/FundamentalAnalysis.jsx";
import TechnicalAnalysis from "./components/Payment/TechnicalAnalysis.jsx";
import CourseReview from "./screens/CourseReview.jsx";
import Terms from "./screens/Terms.jsx";
import Privacy from "./screens/Privacy.jsx";
import Refund from "./screens/Refund.jsx";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Landing />,
        },
        {
          path: "/courses",
          element: <CourseDetails />,
        },
        {
          path: "/coursereview",
          element: <CourseReview />,
        },
        {
          path: "/stockmarketbasics",
          element: <StockMarketBasics />,
        },
        {
          path: "/fundamentalanalysis",
          element: <FundamentalAnalysis />,
        },
        {
          path: "/technicalanalysis",
          element: <TechnicalAnalysis />,
        },
        {
          path: "/payment-info",
          element: <Payment />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/terms&conditions",
          element: <Terms />,
        },
        {
          path: "/privacypolicy",
          element: <Privacy />,
        },
        {
          path: "/refundpolicy",
          element: <Refund />,
        },
        {
          path: "/success",
          element: <Success />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
export default App;
