import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";

export default function WhoCanJoin() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container mb-20">
          <HeaderInfo>
            <h3
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
              className="text-center pb-4  font30 extraBold"
            >
              Who can Join our Course⛳️
            </h3>
          </HeaderInfo>
          {/* <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Stock Market Beginner"
                text="Who has zero knowledge about Stock Market and wants to start investing in it."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Stock Market Trader/Investor"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Knowledge Seeker"
                text="Who wants ton know about Stock Market functions and it benefits."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
          </div> */}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
