import React, { useEffect } from "react";
import PaymentComponent from "../components/Payment/PaymentComponent";
import CourseTypes from "../components/Sections/CourseTypes";
import VideoPlayer from "../components/Sections/VideoPlayer";

const CourseDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <CourseTypes />
      {/* <VideoPlayer url="https://vimeo.com/76979871" /> */}
    </div>
  );
};

export default CourseDetails;
