import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import { useNavigate } from "react-router-dom";
import basic from "../../assets/img/1.png";
import fund from "../../assets/img/2.png";
import tech from "../../assets/img/3.png";
import all from "../../assets/img/4.png";


export default function CourseTypes() {
  const navigate = useNavigate();

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
              className="font40 mt-12 extraBold text-center"
            >
              Courses we offer💥{" "}
            </h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                img={basic}
                price="₹1,500"
                rating="★ ★ ★ ★ ★"
                title="Stock Market Basics"
                text="🔔 in Tamil"
                offers={[
                  { name: "Live Interactive Session", cheked: true },
                  { name: "Enrolled Learners : 500+", cheked: true },
                  { name: "Access to tools and materials", cheked: true },
                  { name: "& more...", cheked: false },
                ]}
                action={() => navigate("/stockmarketbasics")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                img={fund}
                price="₹3,500"
                rating="★ ★ ★ ★ ★"
                // nonRating="★"
                title="Fundamental Analysis"
                text="🔔 in Tamil"
                offers={[
                  { name: "Live Interactive Session", cheked: true },
                  { name: "Enrolled Learners : 500+", cheked: true },
                  { name: "Access to tools and materials", cheked: true },
                  { name: "& more...", cheked: false },
                ]}
                action={() => navigate("/fundamentalanalysis")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                img={tech}
                price="₹2,500"
                title="Technical Analysis"
                text="🔔 in Tamil"
                rating="★★★★★"
                offers={[
                  { name: "Live Interactive Session", cheked: true },
                  { name: "Enrolled Learners : 550+", cheked: true },
                  { name: "Access to tools and materials", cheked: true },
                  { name: "& more...", cheked: false },
                ]}
                action={() => navigate("/technicalanalysis")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                img={all}
                price="₹5,300"
                title="Basics + Fundamental + Technical"
                text="🔔 in Tamil"
                rating="★★★★★"
                offers={[
                  { name: "Live Interactive Session", cheked: true },
                  { name: "Enrolled Learners : 700+", cheked: true },
                  { name: "Access to tools and materials", cheked: true },
                  { name: "& more...", cheked: false },
                ]}
                action={() => navigate('/payment-info')}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
