import React, { useEffect } from "react";
import BasicsInfo from "../Sections/BasicsInfo";
import BasicModule from "../Sections/BasicModule";
import { Link } from "react-router-dom";
import FundamentalModule from "../Sections/FundamentalModule";
import FundInfo from "../Sections/FundInfo";

const FundamentalAnalysis = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-28">
      <h1
        style={{ fontFamily: "Josefin Sans, sans-serif" }}
        className="font30 text-center mt-12 extraBold"
      >
        Fundamental Analysis🚀{" "}
      </h1>
      {/* 1 */}
      <div className="flex flex-wrap  justify-evenly w-[90%] text-[#403f58]   m-auto rounded-xl  mb-10  bg-[#]">
        <main>
          <FundInfo />
        </main>
        <main>
          <FundamentalModule />
        </main>
      </div>
      {/* 3 */}
      <Link to="/payment-info">
        {" "}
        <button className="block m-auto mb-10 py-3 font-semibold  px-4 border bg-green-500 text-white p-1 rounded-lg ">
          Book your slots for ₹ 3500
        </button>
      </Link>
    </div>
  );
};

export default FundamentalAnalysis;
