import React from "react";
import styled from "styled-components";

const Contact = () => {
  return (
    <Wrapper id="contact">
      <div className="mb-44">
        <div
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="container"
        >
          <HeaderInfo>
            <h1
              style={{ fontFamily: "Josefin Sans, sans-serif" }}
              className="font30 mt-10 text-center extraBold"
            >
              Let's get in touch
            </h1>
          </HeaderInfo>
          <div className="mb-20 block m-auto" style={{ paddingBottom: "30px" }}>
            <h2 className="text-xl mt-10">Address:</h2>
            <br /> 4/447, 6th street, Aruna Nagar,
            <br /> K-vadamadurai, Thudiyalur, Coimbatore, <br />
            641017
            <br />
            <br />
            <p className="text-xl">Contact:</p>
            <br />
            +91 9003863517
            <br />
            office@smartstockinvestor.in
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Contact;

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
