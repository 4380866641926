import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "./TestimonialBox";
import VideoPlayer from "../Sections/VideoPlayer";

export default function TestimonialSliderVideo() {
  const settings = {
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    // autoplaySpeed: 1000,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="w-[80%] m-auto">
      <h3
        style={{ fontFamily: "Josefin Sans, sans-serif" }}
        className="font30 font-semibold mb-4 text-center  bold"
      >
        What people say about the course👇
      </h3>
      <Slider {...settings}>
        <LogoWrapper>
          {" "}
          <VideoPlayer url="https://res.cloudinary.com/dqwt5wpun/video/upload/v1721734338/Course_Review_-_Aarthi_vt0iza.mp4" />
        </LogoWrapper>
        <LogoWrapper>
          {" "}
          <VideoPlayer url="https://res.cloudinary.com/dqwt5wpun/video/upload/v1721734723/Course_Review_-_Thenmozhi_h4bmzt.mp4" />
        </LogoWrapper>
        <LogoWrapper>
          {" "}
          <VideoPlayer url="https://res.cloudinary.com/dqwt5wpun/video/upload/v1721734721/Course_Review_-_Balaji_cgufks.mp4" />
        </LogoWrapper>
        <LogoWrapper>
          {" "}
          <VideoPlayer url="https://res.cloudinary.com/dqwt5wpun/video/upload/v1721734716/Course_review_-_Lokesh_raja_sotjd5.mp4" />
        </LogoWrapper>
        <LogoWrapper>
          {" "}
          <VideoPlayer url="https://res.cloudinary.com/dqwt5wpun/video/upload/v1721734712/Course_Review_-_Gokul_rd2pi7.mp4" />
        </LogoWrapper>
        <LogoWrapper>
          {" "}
          <VideoPlayer url="https://res.cloudinary.com/dqwt5wpun/video/upload/v1721734707/Course_review_-_Manick_gbyipo.mp4" />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
