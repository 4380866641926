import React from "react";
import { Link, useLocation } from "react-router-dom";

const Success = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const paymentId = query.get("payment_id");

  return (
    <div className="mt-24 mb-4 flex justify-center">
      {paymentId.length > 1 ? (
        <div>
          <h2
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
            className="font30 text-center mt-12 extraBold"
          >
            Payment Successful🚀...
          </h2>
          <iframe
            className="h-[300px]"
            src="https://lottie.host/embed/034ba9b2-9c4d-47ed-b37f-dea518e1b7ab/ie5MsvGfRV.json"
          ></iframe>
          <p>
            Thankyou🙏 for your payment! <br/><b>Your payment ID is: {paymentId}</b>
          </p>

          <h3>Further info will be shared to your mail id.</h3>
          <Link to="/">
            {" "}
            <button
              type="submit"
              className="m-auto mt-6 mb-10 py-3 flex font-semibold px-4 border bg-green-500 text-white p-1 rounded-lg "
            >
              Go back to home
            </button>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default Success;
