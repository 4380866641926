import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/mentor-3.png";
//icons
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { SlBadge } from "react-icons/sl";


export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="lightBg">
          <h3
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
            className="text-center pt-10 font30 extraBold"
          >
            Course Mentor🔥
          </h3>
          <div className="container pb-6 flex justify-center">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2
                  style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  className="font40 extraBold"
                >
                  Mr.Ragul
                </h2>
                <div className="flex gap-4">
                  <h3 className="coralBg flex gap-2 items-center w-fit tag rounded-lg text-sm ">
                  <SlBadge size={25} />
                  Certified Equity Research Analyst
                  </h3>
                  <h3 className="coralBg flex gap-2 items-center w-fit tag rounded-lg text-sm ">
                  <SlBadge size={25}  />
                   
                    Certified Mutual fund Distributor
                  </h3>
                </div>
                <p
                  style={{ fontFamily: "Josefin Sans, sans-serif" }}
                  className="font17 text-left text-[#4d4d4d]"
                >
                  Welcome to the world of smart investing with Ragul, your
                  dedicated stock market mentor. With a remarkable journey
                  spanning over 6 years, Ragul has mastered the art of trading
                  and investing, and also helps in transforming countless people
                  into confident and successful stock market Investors. Known
                  for his dynamic approach and personalized mentoring, Ragul
                  brings a wealth of knowledge, strategies, tools and guidance
                  that you need to navigate the stock market and achieve your
                  financial goals. He is very Passionate about empowering
                  others; He is here to guide you through the Stock Market
                  Journey. Start your journey with Ragul and take the first step
                  towards your financial freedom today!
                </p>
                {/* numsection */}
                <div className="flex gap-4">
                  <h3 className="coralBg pt-2 w-fit tag rounded-lg font-semibold ">
                    1500+ Trained 🚀
                  </h3>
                  <h3 className="coralBg pt-2  w-fit tag rounded-lg font-semibold ">
                    500+ Reviews 🔥
                  </h3>
                  <h3 className="coralBg pt-2  w-fit tag rounded-lg font-semibold ">
                    Rated 4.5 ⭐️
                  </h3>
                </div>
                {/* end-numsection */}
                <h4 className=" font-semibold pt-2 ">Know more👇</h4>
                {/* socialmedia icons */}
                <div className="iconCenter flex  ">
                  <div className=" bg-[#3653d2] rounded-lg flex  text-white p-2 cursor-pointer mt-1">
                    <a href="https://www.instagram.com/smartstockinvestor?igsh=MTBzaDdiNnBuY2pvZQ==">
                      {" "}
                      <FaInstagram className="mr-3" size={25} />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61558175617995">
                      {" "}
                      <FaFacebook className="mr-3" size={25} />
                    </a>
                    <a href="https://www.linkedin.com/in/ragulravichandran/">
                      {" "}
                      <FaLinkedin className="mr-3" size={25} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCFFYhoJhuOSF6QgF99mx8Fg">
                      {" "}
                      <FaYoutube className="mr-1" size={25} />
                    </a>
                  </div>
                </div>
                {/* <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "" }}>
                    <FullButton
                      title="Get Course Details ✅"
                      action={() => alert("clicked")}
                    />
                  </div>
                </ButtonsRow> */}
              </AddLeft>
              <div className="flexNullCenter">
                <AddImgWrapp1 className="flexCenter">
                  <img
                    style={{ height: "450px" }}
                    src={AddImage1}
                    alt="mentor"
                  />
                </AddImgWrapp1>
              </div>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  width: 90%;
  margin: auto;
  // margin: 80px 0;
  padding: 20px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    // max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
