import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import styled from "styled-components";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

const Payment = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [price, setPrice] = useState(0);
  const navigate = useNavigate();

  //price choosen
  const handleCourseChange = (event) => {
    const course = event.target.value;
    setSelectedCourse(course);

    switch (course) {
      case "Stock Market Basics":
        setPrice(1500);
        break;
      case "Fundamental Analysis":
        setPrice(3500);
        break;
      case "Technical Analysis":
        setPrice(2500);
        break;
      case "Basic + Fundamental + Technical":
        setPrice(5300);
        break;
      default:
        setPrice(0);
        break;
    }
  };

  const handlePayment = async () => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: price * 100,
      currency: "INR",
      name: "Smart Stock Investor",
      description: "Test Transaction",
      handler: async function (response) {
        // Send email confirmation

        try {
          await emailjs.send(
            process.env.REACT_APP_SERVICE_ID, // EmailJS service ID
            process.env.REACT_APP_TEMPLATE_ID, // EmailJS template ID
            {
              to_name: name,
              to_mobile: mobile,
              to_billing_address: billingAddress,
              to_email: email,
              payment_id: response.razorpay_payment_id,
              to_selected_course: selectedCourse,
            },
            process.env.REACT_APP_USER_ID // EmailJS user ID
          );
        } catch (error) {
          console.error("Error sending email:", error);
        }

        navigate(`/success?payment_id=${response.razorpay_payment_id}`);
      },
      prefill: {
        name: name,
        email: email,
        contact: mobile,
      },
      notes: {
        address: billingAddress,
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper id="contact">
      <div className="mt-20 container ">
        <h2
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
          className="font30 text-center mt-12 extraBold"
        >
          Payment Info
        </h2>
        <div className="row" style={{ paddingBottom: "30px" }}>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handlePayment();
              }}
            >
              <label className="font13">First Name:</label>
              <input
                className="border border-slate-600 "
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label>Mobile Number:</label>
              <input
                className="border border-slate-600 "
                type="tel"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
              <label>Email:</label>
              <input
                className="border border-slate-600 "
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label>Choose course : </label>

              <select
                type="course"
                value={selectedCourse}
                onChange={handleCourseChange}
                className="border border-black p-1 cursor-pointer bg-[#0b093b] py-2 px-3 text-white rounded-lg"
              >
                <option value="" disabled>
                  Select a course
                </option>

                <option value="Stock Market Basics">Stock Market Basics</option>
                <option value="Fundamental Analysis">
                  Fundamental Analysis
                </option>
                <option value="Technical Analysis">Technical Analysis</option>
                <option value="Basic + Fundamental + Technical">
                  Basic + Fundamental + Technical
                </option>
              </select>
              <br />
              <br />

              <label>Billing Address:</label>
              <textarea
                className="border  border-slate-600 "
                type="text"
                value={billingAddress}
                onChange={(e) => setBillingAddress(e.target.value)}
                required
              />
              <button
                type="submit"
                className=" m-auto mb-10 py-3 font-semibold  px-4 border bg-green-500 text-white p-1 rounded-lg "
              >
                Pay ₹{price}
              </button>
            </Form>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
            <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
              <ContactImgBox>
                <img src={ContactImg2} alt="office" className="radius6" />
              </ContactImgBox>
            </div>
            <div style={{ width: "50%" }}>
              <div style={{ marginTop: "100px" }}>
                <img src={ContactImg3} alt="office" className="radius6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Payment;

const Wrapper = styled.section`
  width: 100%;
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
